@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-toastify/dist/ReactToastify.min.css';

@import "fonts";
@import "normalize";
@import "loader";
@import "main";

