html {
  -webkit-overflow-scrolling: touch;
}

html, body {
  min-height: 100vh;
}

body {
  font-size: 14px;
  background: radial-gradient(#224646, #182828) repeat;
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  @extend .input;

  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
}

.h1-title {
  color: white;
  font-size: 26px;
}

.h2-title {
  color: white;
  font-size: 24px;
}

.h3-title {
  color: white;
  font-size: 22px;
}

.h4-title {
  color: white;
  font-size: 18px;
}

.h5-title {
  font-size: 16px;
}

.main-container {
  @apply px-5 py-10;
}

.btn {
  &.disabled,
  &:disabled {
    @apply cursor-not-allowed text-gray-700 bg-[#171717]/50 border-0;
  }

  &.btn-disabled-gray {
    @apply text-white bg-gray-800/50;
  }
}

.section-gas-tracker {
  @apply max-w-[1200px] mx-auto grid gap-10;

}

.gas-tracker-cards {
  @apply grid grid-cols-4 gap-10;
}

.gas-tracker-card {
  @apply rounded-2xl border border-solid p-5 grid gap-5 justify-center text-center cursor-pointer transition-all ;

  &.slow {
    @apply border-transparent bg-green-500/10;
    .amount-gwei {
      @apply text-green-500;
    }

    &:hover {
      @apply border-green-500/50 scale-105;
    }
  }

  &.standard {
    @apply border-transparent bg-blue-500/10;

    .amount-gwei {
      @apply text-blue-500;
    }

    &:hover {
      @apply border-blue-500/50 scale-105;
    }
  }

  &.fast {
    @apply border-transparent bg-red-500/10;

    .amount-gwei {
      @apply text-red-500;
    }

    &:hover {
      @apply border-red-500/50 scale-105;
    }
  }

  &.instant {
    @apply border-transparent bg-yellow-800/10;

    .amount-gwei {
      @apply text-yellow-800;
    }

    &:hover {
      @apply border-yellow-800/50 scale-105;
    }
  }
}

.amount-gwei {
  @apply font-bold text-xl;
}

.input-block {
  @apply relative;

  &.valid,
  &.invalid,
  &.warning {
    @apply after:hidden;

    &.input-with-icon {
      @apply after:block after:absolute after:top-4 after:right-5 after:text-xs after:font-['Font_Awesome'];
    }
  }

  &.valid {
    @apply after:text-green-700 after:content-['\f00c'];

    .input,
    .input-append {
      @apply border-green-700;
    }
  }

  &.invalid {
    &.input-with-icon {
      @apply after:text-red-700 after:content-['\e80c'] after:text-[8px] after:font-['fontello'];
    }

    .input,
    .input-append {
      @apply border-red-700;
    }
  }

  &.warning {
    @apply after:text-orange-700 after:content-['\f2ec'];

    .input,
    .input-append {
      @apply border-orange-700;
    }
  }

  &.input-with-icon {
    .input {
      @apply pr-9;
    }
  }
}

.feedback {
  @apply px-5 pt-5;
}

.warning-feedback {
  @apply text-orange-700;
}

.valid-feedback {
  @apply text-green-700;
}

.invalid-feedback {
  @apply text-red-700;
}

.input {
  @apply text-white text-base font-medium rounded-[5px] border border-solid border-white/60 h-12 w-full bg-transparent px-5 text-ellipsis;

  &:disabled {
    @apply bg-white/20 cursor-not-allowed;
  }

  &[type='password'] {
    @apply pr-[5px];
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.textarea-custom {
  @extend .input;
  @apply h-auto p-5 resize-none;
}

.input-append {
  @apply rounded-[5px] border border-solid border-white/60 pr-5;

  &.disabled {
    @apply bg-white/20 cursor-not-allowed;
  }

  input {
    @apply border-0 pr-[5px] bg-transparent;

    &:disabled {
      @apply bg-transparent;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      @apply border-0 bg-transparent;
    }
  }
}

.react-select-wrapper {
  &.valid {
    .react-select {
      &__control {
        @apply border-green-700;
      }
    }
  }

  &.invalid {
    .react-select {
      &__control {
        @apply border-red-700;
      }
    }
  }

  &.warning {
    .react-select {
      &__control {
        @apply border-orange-700;
      }
    }
  }
}

.label-primary {
  @apply block text-white font-medium mb-5 px-5;
}

.table-container {
  @apply flex flex-col flex-nowrap border-b border-solid border-gray-700/20;
}

.table-row-heading {
  @apply border-0 text-gray-700 border-b border-solid border-gray-700/20 bg-zinc-700;
}

.table-body {
  @apply px-5;

  .table-row {
    @apply text-white;

    &:not(&:last-child) {
      @apply border-b border-solid border-gray-700/20;
    }
  }
}

.table-row {
  @apply flex lg:flex-nowrap flex-wrap w-full;

  &-open {
    @apply flex-wrap bg-black/20;
  }
}

.row-item {
  min-width: 10rem;
  @apply flex items-center leading-none px-5 transition-all flex-1 py-5;

  &__mark {
    @apply relative;

    &:before {
      @apply content-[''] -translate-y-1/2 absolute -left-5 top-1/2 bg-orange-700 h-[14px] w-0.5;
      border-radius: 0 3px 3px 0;
    }
  }

  &-check {
    @apply relative;

    &:after {
      @apply content-['\f00c'] absolute right-5 top-1/2 -translate-y-1/2 text-green-700;
      font-family: 'Font Awesome';
    }
  }

  &-highlighted {
    @apply relative;

    &:before {
      @apply content-[''] -z-1 absolute left-0 right-0 top-0.5 bottom-0.5 rounded-[5px];
      background: linear-gradient(
                      90deg,
                      rgba(255, 255, 255, 0.1) 0%,
                      rgba(255, 255, 255, 0) 100%
      );
    }
  }

  & > *:not(:last-child) {
    margin-right: 5px;
  }
}

.modal {
  @apply h-full w-full fixed bg-black/40 top-0 left-0 flex justify-center items-center opacity-0 transition-opacity pointer-events-none z-[999];

  &.active {
    @apply opacity-100 pointer-events-auto;
  }

  &-header {

  }

  &-content {
    @apply rounded-2xl bg-zinc-700 scale-50 transition-transform min-h-[100px] w-full max-w-[500px] min-w-[100px];

    &.sm {
      @apply max-w-[300px]
    }

    &.lg {
      @apply max-w-[800px]
    }

    &.xl {
      @apply max-w-[1140px]
    }

    &.active {
      @apply scale-100;
    }

    &__header {
      @apply flex items-center text-2xl relative py-5 pl-5 pr-14 text-white text-lg font-medium border-b border-solid border-blueGray-300;
    }

    &__body {
      @apply p-5 max-h-[80vh] overflow-y-auto;
    }
  }
}

.dropdown {
  @apply relative;

  &-trigger {
    cursor: pointer;
  }

  &-menu {
    @apply bg-[#182828]/20 shadow-2xl backdrop-blur-2xl p-5 rounded-2xl;

    &__wrapper {
      @apply pt-5 hidden absolute left-0 top-full z-10;

      &.show {
        @apply block;
      }
    }

    &__item {
      @apply border-b border-solid py-5 border-white/20;

      &:last-child {
        @apply border-0 pb-0;
      }
    }
  }
}